import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar.tsx";
import Home from "./pages/Home.tsx";
import Buttons from "./pages/Buttons.tsx";
import Typography from "./pages/Typography.tsx";
import "./App.css";
import Colours from "./pages/Colours.tsx";
import Gradients from './pages/Gradients.tsx';
import "material-ui-jokerpro/dist/joker_tokens.css";



const App = () => {
  return (
    <Router>
      <div className="app">
        <Sidebar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/typography" element={<Typography />} />
            <Route path="/colours" element={<Colours />} />
            <Route path="/gradients" element={<Gradients />} />
            
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
