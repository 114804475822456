import React from "react";
import { JokerButton, ButtonType, ButtonSize } from "material-ui-jokerpro";


const Buttons = () => {
  return (
    <div className="content">
 <h1 className="page-title whiteText">Button Components</h1>
 <p className="intro-paragraph">For typography I have created global classes in the joker token file so you can simply add the class to an element, instead of adding al the variables. It contains the weight; font-size; margin reset, and line height. The main thing missing is color.<br></br>If you would like to set a color you can just add another class with color: var(--xxx) or an inline color style with the color variable. For example an element might look like this: className="heading01 redColor" </p>
 <hr className="intro-divider"></hr>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.CTA}
          size={ButtonSize.Small}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Link}
          size={ButtonSize.Small}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Primary}
          size={ButtonSize.Small}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Secondary}
          size={ButtonSize.Small}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Text}
          size={ButtonSize.Small}
        />
      </div>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.CTA}
          size={ButtonSize.Normal}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Link}
          size={ButtonSize.Normal}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Primary}
          size={ButtonSize.Normal}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Secondary}
          size={ButtonSize.Normal}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Text}
          size={ButtonSize.Normal}
        />
      </div>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.CTA}
          size={ButtonSize.Large}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Link}
          size={ButtonSize.Large}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Primary}
          size={ButtonSize.Large}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Secondary}
          size={ButtonSize.Large}
        />
        <JokerButton
          text={"Test Button"}
          href={"/"}
          type={ButtonType.Text}
          size={ButtonSize.Large}
        />
      </div>
    </div>
  );
};

export default Buttons;
