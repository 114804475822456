import React from "react";
import { JokerButton } from "material-ui-jokerpro";
import "./Typography.css";
import CodeCopyText from "./codeCopyText.tsx";

const Buttons = () => {
  return (
    <div className="content">
      <h1 className="page-title whiteText">Typography Classes</h1>
      <p className="intro-paragraph">For typography I have created global classes in the joker token file so you can simply add the class to an element, instead of adding al the variables. It contains the weight; font-size; margin reset, and line height. The main thing missing is color.<br></br>If you would like to set a color you can just add another class with color: var(--xxx) or an inline color style with the color variable. For example an element might look like this: className="heading01 redColor" </p>
      <hr className="intro-divider"></hr>
      <div className="inner-content">
         <h2 className="heading02" style={{ color: 'var(--color-jokerAlert400)' }}>Global classes</h2>
      <section>
        <h1 className="whiteText heading01">Heading 1</h1>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>heading01</code>
          </pre>
       
          
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .heading01 <br />
              REACT/JS: className="heading01"
            </code>
          </div>
        </div>
      </section>

      <section>
        <h2 className="whiteText heading02">Heading 2</h2>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>heading02</code>
          </pre>
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .heading02 <br />
              REACT/JS: className="heading02"
            </code>
          </div>
        </div>
      </section>

      <section>
        <h3 className="whiteText heading03">Heading 3</h3>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>heading03</code>
          </pre>
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .heading03 <br />
              REACT/JS: className="heading03"
            </code>
          </div>
        </div>
      </section>
      <section>
        <h4 className="whiteText heading04">Heading 4</h4>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>heading04</code>
          </pre>
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .heading04 <br />
              REACT/JS: className="heading04"
            </code>
          </div>
        </div>
      </section>

      <section>
        <p className="whiteText paragraph01">Paragraph 01</p>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>paragraph01</code>
          </pre>
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .paragraph01 <br />
              REACT/JS: className="paragraph01"
            </code>
          </div>
        </div>
      </section>

      <section>
        <p className="whiteText paragraph02">Paragraph 02</p>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>paragraph02</code>
          </pre>
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .paragraph02 <br />
              REACT/JS: className="paragraph02"
            </code>
          </div>
        </div>
      </section>

      <section>
        <p className="whiteText paragraph02">Paragraph 03</p>
        <div className="codeholder">
        <p className="badge global-class"> Global Class</p>
          <pre className="code">
            <p className="style-code-name">className=</p>
            <code>paragraph03</code>
          </pre>
          <div className="code-example">
            example
            <br />
            <code>
              HTML: .paragraph03 <br />
              REACT/JS: className="paragraph03"
            </code>
          </div>
        </div>
      </section>
      </div>
      <CodeCopyText />
    </div>
  );
};

export default Buttons;
