import React, { useState, useEffect } from "react";
import copyIcon from "../img/copyIcon.svg";


const GradientOpacitySelector = ({ baseColor }) => {
  const [selectedValue, setSelectedValue] = useState(
    `var(--color-${baseColor})`
  );
  const [copied, setCopied] = useState(false);
  const [iconSrc, setIconSrc] = useState(copyIcon);

  const opacityValues = [
    { label: "100%", value: "default" },    
  ];
useEffect(() => {
 setIconSrc(copyIcon);
}, [copied, iconSrc]);

  

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedValue).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <div className="opacity-selector-container">
      <label>{copied ? "Copied!" : "Opacity"}</label>
      <select
        className="opacity-selector"
        id={baseColor}
        onChange={handleChange}
        value={selectedValue}
      >
        {opacityValues.map(({ label, value }, index) => (
          <option
            key={index}
            value={
              value === "default"
                ? `var(--color-${baseColor})`
                : `var(--color-${baseColor}-opacity${value})`
            }
          >
            {label}
          </option>
        ))}
      </select>
      
        <button
          onClick={handleCopy}
          className={`${copied ? 'copySuccess' : 'uncopySuccess'}`}
        >
          
          {copied && 
            <span>✅</span>
          }
          {!copied && 
            <img className="copy-icon" src={copyIcon} alt="copy icon" />
          }
        </button>
      
    </div>
  );
};

export default GradientOpacitySelector;