import React from "react";
import "./Gradients.css";
import GradientChip from './colors/components/GradientChip.tsx';
import gradientChips from './colors/data/gradientChips.json';




const Gradients = () => {


  return (
    <div className="content">
      
      <h1 className="page-title whiteText">Gradients</h1>
      <p className="intro-paragraph">These are the gradients their values all use the color Variable defined by color system. </p>
      <hr className="intro-divider"></hr>
      <div className="swatch-container">
    
         <section className="swatch-group">
         
            {gradientChips.jokerGradientGold01.map((chip, index) => (
              <GradientChip 
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

        <section className="swatch-group">
         
            {gradientChips.jokerGradientGold02.map((chip, index) => (
              <GradientChip 
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>
        <section className="swatch-group">
         
            {gradientChips.jokerGradientBlack01.map((chip, index) => (
              <GradientChip 
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>
        <section className="swatch-group">
         
            {gradientChips.jokerGradientBlack02.map((chip, index) => (
              <GradientChip 
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

        <section className="swatch-group">
         
            {gradientChips.jokerGradientHoney01.map((chip, index) => (
              <GradientChip 
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

        <section className="swatch-group">
        
            {gradientChips.jokerGradientSilver01.map((chip, index) => (
              <GradientChip 
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
       
        </section>


       

      
      </div>

    </div>
  );
};

export default Gradients;
