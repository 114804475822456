import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const location = useLocation();
  const menuItems = [
    { to: "/", label: "Home" },
    { to: "/buttons", label: "Buttons" },
    { to: "/typography", label: "Typography" },
    { to: "/colours", label: "Colors" },
    { to: "/gradients", label: "Gradients" },
  ];

  const handleClick = (id: string) => {
    setSelectedItem(id);
  };

  return (
    <div className="sidebar">
      
      <nav>
        <ul>
          <div className="inner-sidebar-desktop">
            {menuItems.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className={`sidebar-item ${
                  location.pathname === item.to ? "selected" : ""
                }`}
              >
                {item.label}
              </Link>
            ))}
          </div>

          <div className="inner-sidebar-mobile">
            <label style={{ color: `var(--color-jokerBlack100)`, marginBottom : "0.5rem", display: "block" }}>Select section</label>
          <select 
            value={location.pathname}
            onChange={(e) => window.location.href = e.target.value}
          >
            {menuItems.map((item) => (
              <option key={item.to} value={item.to}>
                {item.label}
              </option>
            ))}
          </select>
          </div>

          
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
