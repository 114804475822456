import React from 'react';
import OpacitySelector from "./OpacitySelector.tsx"
import GradientOpacitySelector from './GradientOpacitySelector.tsx';


const GradientChip: React.FC = ({ baseColor, borderColor, textColor }) => {
  return (
    <div className="colorChip" style={{ 
      background: `var(--color-${baseColor})`,
      border: `1px solid var(--color-${borderColor})`,
      color: `var(--color-${textColor})`
    }}>
      <p className="color-name">{baseColor}</p>
      <GradientOpacitySelector baseColor={baseColor}/>
    
    </div>
  );
};

export default GradientChip;