import React, { useState, useEffect } from "react";
import copyIcon from "../img/copyIcon.svg";

const OpacitySelector = ({ baseColor }) => {
  const [selectedValue, setSelectedValue] = useState(
    `var(--color-${baseColor})`
  );
  const [copied, setCopied] = useState(false);
  const [iconSrc, setIconSrc] = useState(copyIcon);

  const opacityValues = [
    { label: "100%", value: "default" },
    { label: "95%", value: "95" },
    { label: "90%", value: "90" },
    { label: "85%", value: "85" },
    { label: "80%", value: "80" },
    { label: "75%", value: "75" },
    { label: "70%", value: "70" },
    { label: "65%", value: "65" },
    { label: "60%", value: "60" },
    { label: "55%", value: "55" },
    { label: "50%", value: "50" },
    { label: "45%", value: "45" },
    { label: "40%", value: "40" },
    { label: "35%", value: "35" },
    { label: "30%", value: "30" },
    { label: "25%", value: "25" },
    { label: "20%", value: "20" },
    { label: "15%", value: "15" },
    { label: "10%", value: "10" },
    { label: "5%", value: "05" },
    { label: "0%", value: "00" },
  ];
useEffect(() => {
 setIconSrc(copyIcon);
}, [copied, iconSrc]);

  

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedValue).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <div className="opacity-selector-container">
      <label>{copied ? "Copied!" : "Opacity"}</label>
      <select
        className="opacity-selector"
        id={baseColor}
        onChange={handleChange}
        value={selectedValue}
      >
        {opacityValues.map(({ label, value }, index) => (
          <option
            key={index}
            value={
              value === "default"
                ? `var(--color-${baseColor})`
                : `var(--color-${baseColor}-opacity${value})`
            }
          >
            {label}
          </option>
        ))}
      </select>
      
        <button
          onClick={handleCopy}
          className={`${copied ? 'copySuccess' : 'uncopySuccess'}`}
        >
          
          {copied && 
            <span>✅</span>
          }
          {!copied && 
            <img className="copy-icon" src={copyIcon} alt="copy icon" />
          }
        </button>
      
    </div>
  );
};

export default OpacitySelector;