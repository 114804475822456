import React from "react";
import "./Colours.css";
import ColorChip from "./colors/components/ColorChip.tsx";
import colorChips from "./colors/data/colorChips.json";





const Colours = () => {


  return (
    <div className="content">
      
      <h1 className="page-title whiteText">Colour Variables</h1>
      <p className="intro-paragraph">For typography I have created global classes in the joker token file so you can simply add the class to an element, instead of adding al the variables. It contains the weight; font-size; margin reset, and line height. The main thing missing is color.<br></br>If you would like to set a color you can just add another class with color: var(--xxx) or an inline color style with the color variable. For example an element might look like this: className="heading01 redColor" </p>
      <hr className="intro-divider"></hr>
      <div className="swatch-container">
    
         <section className="swatch-group">
         
            {colorChips.jokerBlack.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

        <section className="swatch-group">
         
            {colorChips.jokerGold.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>
        <section className="swatch-group">
         
            {colorChips.jokerRose.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>
        <section className="swatch-group">
         
            {colorChips.jokerHoney.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

        <section className="swatch-group">
         
            {colorChips.jokerGreen.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

        <section className="swatch-group">
        
            {colorChips.jokerRed.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
       
        </section>


        <section className="swatch-group">
       
            {colorChips.jokerAlert.map((chip, index) => (
              <ColorChip
                key={index}
                baseColor={chip.baseColor}
                borderColor={chip.borderColor}
                textColor={chip.textColor}
              />
            ))}
        
        </section>

      
      </div>

    </div>
  );
};

export default Colours;
