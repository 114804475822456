import React from "react";

const Home = () => {
  return (
    <div className="content">
      
      <h1 className="page-title whiteText">Home Page</h1>
      <p className="intro-paragraph">For typography I have created global classes in the joker token file so you can simply add the class to an element, instead of adding al the variables. It contains the weight; font-size; margin reset, and line height. The main thing missing is color.<br></br>If you would like to set a color you can just add another class with color: var(--xxx) or an inline color style with the color variable. For example an element might look like this: className="heading01 redColor" </p>
      <hr className="intro-divider"></hr>
    </div>
  );
};

export default Home;
